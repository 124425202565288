@import "ColorScheme";

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$body-bg: map-get($custom-colors, "off-white");
$btn-border-radius: 2px;
$input-border-radius: 2px;
$component-active-bg: map-get($custom-colors, "dark-grey");

// 3. Include remainder of required Bootstrap stylesheets
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here
$theme-colors: map-merge($theme-colors, $custom-colors);

$font-sizes: map-merge($font-sizes, (7: $font-size-base * 0.9, 8: $font-size-base * 0.75, 9: $font-size-base * 0.5));

$utilities: (
	"max-height": (property: max-height,
		class: mh,
		values: (5:5%, 10:10%, 15:15%, 20:20%, 25:25%, 30:30%, 35:35%, 40:40%, 45:45%, 50:50%, 55:55%, 60:60%, 65:65%, 70:70%, 75:75%, 80:80%, 85:85%, 90:90%, 95:95%, 100:100%)),
	"viewport-height": (property: height,
		class: vh,
		values: (5:5vh, 10:10vh, 15:15vh, 20:20vh, 25:25vh, 30:30vh, 35:35vh, 40:40vh, 45:45vh, 50:50vh, 55:55vh, 60:60vh, 65:65vh, 70:70vh, 75:75vh, 80:80vh, 85:85vh, 90:90vh, 95:95vh, 100:100vh)),
	"max-width": (property: max-width,
		class: mw,
		values: (5:5%, 10:10%, 15:15%, 20:20%, 25:25%, 30:30%, 35:35%, 40:40%, 45:45%, 50:50%, 55:55%, 60:60%, 65:65%, 70:70%, 75:75%, 80:80%, 85:85%, 90:90%, 95:95%, 100:100%)),
	"bg-opacity": (css-var: true,
		class: bg-opacity,
		values: (5:5%, 10:10%, 15:15%, 20:20%, 25:25%, 30:30%, 35:35%, 40:40%, 45:45%, 50:50%, 55:55%, 60:60%, 65:65%, 70:70%, 75:75%, 80:80%, 85:85%, 90:90%, 95:95%, 100:100%)),
	"text-opacity": (css-var: true,
		class: text-opacity,
		values: (5:5%, 10:10%, 15:15%, 20:20%, 25:25%, 30:30%, 35:35%, 40:40%, 45:45%, 50:50%, 55:55%, 60:60%, 65:65%, 70:70%, 75:75%, 80:80%, 85:85%, 90:90%, 95:95%, 100:100%))
);

// 5. Include remainder of required parts
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
// @import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
// @import "../../node_modules/bootstrap/scss/dropdown";
// @import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
// @import "../../node_modules/bootstrap/scss/card";
// @import "../../node_modules/bootstrap/scss/accordion";
// @import "../../node_modules/bootstrap/scss/breadcrumb";
// @import "../../node_modules/bootstrap/scss/pagination";
// @import "../../node_modules/bootstrap/scss/badge";
// @import "../../node_modules/bootstrap/scss/alert";
// @import "../../node_modules/bootstrap/scss/progress";
// @import "../../node_modules/bootstrap/scss/list-group";
// @import "../../node_modules/bootstrap/scss/close";
// @import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
// @import "../../node_modules/bootstrap/scss/tooltip";
// @import "../../node_modules/bootstrap/scss/popover";
// @import "../../node_modules/bootstrap/scss/carousel";
// @import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/offcanvas";
// @import "../../node_modules/bootstrap/scss/placeholders";
@import "../../node_modules/bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../../node_modules/bootstrap/scss/utilities/api";